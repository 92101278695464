import { LoginTypeEnum } from "@common/types"
import { SettingsEnum } from "@common/types"

export const SITE_CONFIG = {
  homePath: "home",
  ga: "G-W0S1V579N3",
  favicon: "faviconCr.ico",
  loginType: [LoginTypeEnum.GoogleLogin, LoginTypeEnum.Magiclink],
  logo: {
    white: "logo-small-wide.svg",
    black: "logo-small-wide.svg",
    // "icon-white": "currents-icon-white.svg",
    // "icon-black": "currents-icon-black.svg",
    // "icon-blue": "currents-logo-blue.svg",
    svgName: "currents",
  },
  siteName: "ACME.BOT",
  site: "acme",
  siteShort: "ACME.BOT",
  company: "ACME.BOT",
  address: {
    line1: "68 Circular Road",
    line2: "#02-01 Singapore (049422)",
  },
  seo: {
    url: "https://acme.bot",
    logoUrl: "https://acme.bot/images/acme-112-black.png",
    title: "AI tools to run your business - Acme Bot",
    description:
      "AI tools and agents - Web Research, Writer, Diagram Generator, Content Planner, etc - to automate the most tedious parts of your business.",
    homeImageUrl: "https://acme.bot/images/acme-og.png",
    siteName: "ACME.BOT",
    locale: "en_US",
  },
  footer: {
    visible: false,
  },
  footerSummary:
    "AI tools and agents to automate the most tedious parts of your business.",
  contactEmail: "human@acme.bot",
  faqs: [],
  footerLinks: [],
  pricing: {
    /* plans: triPlans, compare: triPlanComparison */
  },
  headerLinks: [],
  headerCtaLinks: [
    {
      name: "Sign in",
      link: "/login",
    },
    {
      name: "Sign Up",
      link: "/login",
    },
  ],
  /**
   * NOTE:
   * rename this as headerCtaLinks (delete old one)
   * when header.js uses new NavDesktopMenu component
   */
  headerCtaLinksNew: [
    {
      display: "Sign in",
      links: "/login",
    },
    {
      display: "Sign Up",
      links: "/login",
    },
  ],

  helpLink: "https://acme.bot/docs",
  settingsConfig: [SettingsEnum.appearance],
}
